import React, { useState } from "react";
import { Select } from "baseui/select";

const AutosuggestInput = ({
  value,
  placeholder,
  apiMethod,
  apiPath,
  onInputChange,
  onSelect,
  searchInputOverrides,
}) => {
  // Current value of the select field
  const [currentValue, setValue] = useState(value);
  // Data that will be rendered in the autocomplete
  // As it is asynchronous, it is initially empty
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.value !== nextProps.value) {
  //     this.setState({
  //       value: nextProps.value,
  //     });
  //   }
  // }

  /**
   * Updates the state of the autocomplete data with the remote data obtained via AJAX.
   *
   * @param {String} searchText content of the input that will filter the autocomplete data.
   * @return {Nothing} The state is updated but no value is returned
   */
  const retrieveDataAsynchronously = (searchText) => {
    if (searchText.length >= 3) {
      apiMethod(searchText, apiPath).then((result) => {
        setAutocompleteOptions(result.result.suggest);
      });
    }
  };

  /**
   * Callback triggered when the user types in the autocomplete field
   *
   * @param {Event} e JavaScript Event
   * @return {Event} Event of JavaScript can be used as usual.
   */
  const onChange = (value) => {
    /**
     * Handle the remote request with the current text !
     */
    retrieveDataAsynchronously(value);
    if (onInputChange) {
      onInputChange(value);
    }
  };

  /**
   * Callback triggered when the autocomplete input changes.
   *
   * @param {Object} val Value returned by the getItemValue function.
   * @return {Nothing} No value is returned
   */
  const handleOnSelect = (value) => {
    setValue(value);
    if (onSelect) {
      onSelect(value?.[0]?.id || "");
    }
  };

  return (
    <Select
      options={autocompleteOptions.map((item) => ({
        id: item,
        label: item,
      }))}
      value={currentValue}
      placeholder={placeholder}
      onChange={({ value }) => handleOnSelect(value)}
      onInputChange={(e) => onChange(event.target.value || "")}
      backspaceClearsInputValue
      openOnClick={false}
      overrides={{
        ...searchInputOverrides,
        Root: {
          ...searchInputOverrides?.Root,
          style: {
            ...searchInputOverrides?.Root?.style,
            width: "100%",
          },
        },
        DropdownContainer: {
          ...searchInputOverrides?.DropdownContainer,
          style: {
            ...searchInputOverrides?.DropdownContainer?.style,
            zIndex: "1000",
          },
        },
        Popover: {
          props: {
            overrides: {
              Body: {
                style: {
                  zIndex: "1000",
                },
              },
            },
          },
        },
        SingleValue: {
          style: {
            height: "auto",
          },
        },
        ControlContainer: {
          ...searchInputOverrides?.ControlContainer,
          style: {
            ...searchInputOverrides?.ControlContainer?.style,
            backgroundColor: "#fff",
            minHeight: "60px",
          },
        },
      }}
    />
  );
};

export default AutosuggestInput;
