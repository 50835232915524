import React from "react";
import CeWrapper from "../../../global/CeWrapper";
import SearchFormWidget from "../../../../../jobs/components/widgets/SearchFormWidget/SearchFormWidget";
import CeDataGetter from "../../../../libs/CeDataGetter";

const ce = ({ ceData }) => {
  return (
    <CeWrapper ceData={ceData} renderHeader={false}>
      <SearchFormWidget
        title={CeDataGetter.getCeMainHeadlineTitle(ceData)}
        subTitle={CeDataGetter.getCeSubHeadlineTitle(ceData)}
      />
    </CeWrapper>
  );
};

export default ce;
