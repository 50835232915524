import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styles from "./SearchFormWidget.module.scss";
import { doSearch } from "../../../operations/search";
import AutosuggestInput from "../../jobSearch/AutosuggestInput/AutosuggestInput";
import PagePathUtility from "../../../../../libs/PagePathUtility";
import Router from "next/router";
import {
  setSearchFilter,
  setSearchLocation,
  setSearchLocationRadius,
  setSearchQuery,
} from "../../../actions";
import {
  fetchAutosuggest,
  fetchSearch,
} from "../../../services/JobsApiService";
import SearchConfigFactoryLib from "../../../libs/SearchConfigFactoryLib";
import SearchInput from "../../jobSearch/SearchInput";
import { getSearchArgs } from "../../../reducer";
import Button from "../../../../../components/Button";

const SearchFormWidget = ({
  title,
  subTitle = "",
  query = "",
  location = "",
  setSearchFilter,
  setSearchLocation,
  setSearchLocationRadius,
  doSearch,
  searchArgs,
}) => {
  const [facets, setFacets] = useState({});
  const [selectedFacets, setSelectedFacets] = useState({});

  useEffect(() => {
    let isMounted = true;
    const searchConfig = SearchConfigFactoryLib.setLimit(1);
    fetchSearch(searchConfig).then((resp) => {
      const facets = resp?.result?.facets;
      const { geodatas, ...searchFacets } = facets;
      isMounted && setFacets(searchFacets);
    });
    return () => {
      isMounted = false;
    };
  }, []);

  const handleSearchChange = (selectedFacets) => {
    const searchFacets = {};
    for (const facetItem of selectedFacets) {
      const { facetTypeKey, uid } = facetItem;
      if (!searchFacets[facetTypeKey]) {
        searchFacets[facetTypeKey] = [];
      }
      searchFacets[facetTypeKey].push(uid);
    }
    setSelectedFacets(searchFacets);
  };

  const search = (e) => {
    const jobSearchlLink = PagePathUtility.getPathConfig("jobSearch");
    setSearchFilter(selectedFacets);
    doSearch(e).then(() => {
      Router.push(jobSearchlLink).then(() => {
        if (window && window.innerWidth < 767) {
          const scrollToElement = document.querySelector(".search-form-body");

          if (scrollToElement) {
            // 50 = page header size (hardcoded because of flexible header height on mobile)
            const offsetTop =
              window.pageYOffset +
              scrollToElement.getBoundingClientRect().top -
              50;
            window.scroll({
              top: offsetTop,
              behavior: "smooth",
            });
          }
        }
      });
    });
  };

  return (
    <div className={styles.searchFormCenter}>
      <form className={styles.searchFormHead} id="search_form">
        <h1 className={styles.searchFormTitle}>{title}</h1>
        <div className="grid-x grid-margin-x grid-margin-y">
          <div className="cell cell-block-container">
            <SearchInput
              facets={facets}
              maxDropdownHeight="300px"
              placeholder="Tätigkeit, Rechtsgebiet oder Arbeitgeber"
              searchInputOverrides={{
                Placeholder: {
                  style: {
                    color: "#cacaca",
                  },
                },
                ControlContainer: {
                  style: {
                    border: "4px solid #333",
                  },
                },
              }}
              // onSearchFormInputChange={handleSearchFormInputChange}
              onSearchChange={handleSearchChange}
            />
          </div>
          <div className="cell cell-block-container large-6">
            <AutosuggestInput
              placeholder="Stadt oder Postleitzahl"
              value={location}
              className={styles.searchInputCity}
              apiMethod={fetchAutosuggest}
              apiPath="location"
              id="search-city"
              onSelect={(value) => setSearchLocation([value])}
              name="location"
              searchInputOverrides={{
                Placeholder: {
                  style: {
                    color: "#cacaca",
                  },
                },
                ControlContainer: {
                  style: {
                    border: "4px solid #333",
                  },
                },
              }}
            />
          </div>
          <div className="cell large-6">
            <select
              name="radius"
              className={styles.searchInputSelect}
              id="search-radius"
              onChange={(e) => setSearchLocationRadius(e.target.value)}
              defaultValue={searchArgs?.radius || 50}
            >
              {[10, 20, 30, 40, 50, 75, 100].map((radius) => (
                <option value={radius} key={`radius-${radius}`}>
                  {radius} km
                </option>
              ))}
            </select>
          </div>
        </div>
        {subTitle && <h5 className={styles.searchMessage}>{subTitle}</h5>}
        <Button
          id="ltostellen_search_form_submit"
          layout="primary"
          onClick={search}
        >
          Suchen
        </Button>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  searchArgs: getSearchArgs(state),
});

const mapDispatchToProps = {
  doSearch: doSearch,
  setSearchFilter,
  setSearchQuery,
  setSearchLocation,
  setSearchLocationRadius,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchFormWidget);
